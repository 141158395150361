@import "codemirror/lib/codemirror.css";
@import "codemirror/theme/material.css";

html,
body {
  margin: 0;
  padding: 0;
  background: #000;
  font-family: "Lucida Console", Monaco, monospace;
  color: #fff;
  width: 100%;
  height: 100%;
  font-size: 100%;
}

button {
  font-family: inherit;
  font-size: inherit;
}

#app {
  height: 100%;
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: 1.5rem 50px 1fr 1fr 2rem;
  grid-template-areas:
    "title title"
    "header header"
    "sketch  preview"
    "sketch  video"
    "sketch  footer";
}

h1 {
  grid-area: title;
  margin: 0;
  font-size: 0.6rem;
  line-height: 1.5rem;
  padding: 0 1rem;
  background: #111;
  border-bottom: 1px solid #222;
}

header {
  grid-area: header;
  display: flex;
  line-height: 50px;
  border-bottom: 1px solid #222;
}

.header_item {
  border-right: 1px solid #222;
  padding: 0 1rem;
  vertical-align: middle;
  button {
    height: calc(100% - 10px);
    margin: 5px 0;
    padding: 2px 10px;
    vertical-align: middle;
  }
  input[type="number"] {
    width: 3rem;
    font-family: inherit;
    border: 1px solid #222;
    text-align: right;
    background: #333;
    color: #fff;
    padding: 5px;
    vertical-align: middle;
  }
}

.sketch {
  grid-area: sketch;
  position: relative;
  background: #263238;
  .react-codemirror2 {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 2rem 0;
  }
  .CodeMirror {
    height: 100%;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 0.9rem;
  }
}

.preview {
  grid-area: preview;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    background: #ccc;
  }
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  color: #fff;
  font-size: 0.7rem;
  padding: 0.2rem;
  z-index: 99;
}

.video {
  grid-area: video;
  position: relative;
  text-align: center;
  video {
    max-width: 100%;
    height: 100%;
    margin: auto;
  }
}

footer {
  color: #aaa;
  grid-area: footer;
  font-size: 0.6rem;
  line-height: 2rem;
  text-align: right;
  padding: 0 1rem;
  a {
    color: #aaa;
  }
}
